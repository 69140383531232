<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="contract_managers" :translate-params="{id: contract_manager.id, name: contract_manager.name}">Edit Contract Manager  #%{id} - %{name}</translate>
    </h1>
    <Form :data="contract_manager" @save="onSave" :save_text="$pgettext('form','Save')" edit/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import Form from "@/domain/contract_manager/components/Form.vue";

export default {
  name: 'EditContractManager',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchContractManager(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'contract_manager.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('contractManager', [
      'contract_manager',
    ]),
  },
  methods: {
    ...mapActions('contractManager', [
      'fetchContractManager',
      'updateContractManager',
    ]),
    async onSave(data) {
      await this.updateContractManager({id: this.contract_manager.id,data: data})
      await this.$router.push({name: 'contract_manager.list'})
      throw new SuccessNotification(this.$pgettext('contract_manager', 'contract_manager updated!'))
    }
  }
}
</script>

<style scoped>

</style>